import axiosAuth from '@/utils/AxiosAuth'
import { useRuntimeStore } from '@/stores/runtimeStore'
import {notify} from "notiwind";

export const fetchAccounts = async () => {
  const root = `${useRuntimeStore().$state.apiRoot}/billing`
  let result

  await axiosAuth
    .get(`${root}/quickbooks/accounts`)
    .then((res) => {
      console.log('fetchAccounts', res.data)
      result = res.data
      return result
    })
    .catch((err) => {
      console.log('getQuickbooksAccount', err)
    })
  return result
}

export const findItem = async (itemName, findPartial) => {
  const root = `${useRuntimeStore().$state.apiRoot}/billing`
  let params = {
    partial_match: findPartial
  }
  let result
  await axiosAuth
    .get(`${root}/quickbooks/item/${itemName}/`, { params })
    .then((res) => {
      result = res.data
    })
    .catch((err) => {
      console.log(err.response.data)
    })
  return result
}
export const findCustomer = async (clientName, findPartial) => {
  console.log('findCustomer', clientName)
  const root = `${useRuntimeStore().$state.apiRoot}/billing`
  let params = {
    partial_match: findPartial
  }
  let result
  await axiosAuth
    .get(`${root}/quickbooks/customer/${clientName}/`, { params })
    .then((res) => {
      result = res.data
      return result
    })
    .catch((err) => {
      console.log(err.response.data)
    })
  return result
}

// export const createProduct = async (item) => {

// }

export const lookupCustomer = async (searchText) => {
  const root = `${useRuntimeStore().$state.apiRoot}/billing`
  let result
  await axiosAuth
    .get(`${root}/quickbooks/customer/${searchText}`)
    .then((res) => {
      result = res.data
      return result
    })
    .catch((err) => {
      console.log(err)
      result = false
    })
  return result
}

export const createCustomer = async (clientId) => {
  let result
  const root = `${useRuntimeStore().$state.apiRoot}/billing`
  console.log('clientId', clientId)
  await axiosAuth
    .post(`${root}/quickbooks/customer/${clientId}`)
    .then((res) => {
      console.log('createQbCustomer', res.data)
      result = res.data
      return res.data
    })
    .catch((err) => {
      console.log('getQuickbooksAccount', err)
    })
  return result
}

export const sendInvoice = async (recipients, projectId, estimateId, arboristMail) => {
  const root = `${useRuntimeStore().$state.apiRoot}`
  const body = {
    recipients: recipients,
    arboristMail: arboristMail
  }
  await axiosAuth
    .post(
      `${root}/projects/${projectId}/estimate/${estimateId}/email_pdf`,
      body
    )
    .then((res) => {
      console.log('sendInvoice', res.data)
        notify(
            {
                group: 'success',
                title: 'Success',
                text: 'Mail Has Been Send Successfully.'
            },
            2000
        )
      return res.data
    })
    .catch((err) => {
        console.log(err)
        notify(
            {
                group: 'error',
                title: 'Error Sending Invoice',
                text: 'Please check your Corp. E-mail in Company Setting.'
            },
            2000
        )
    })
}
export const downloadInvoice = async (projectId, estimateId) => {
  const root = `${useRuntimeStore().$state.apiRoot}`

  await axiosAuth
    .get(`${root}/projects/${projectId}/estimate/${estimateId}/pdf`)
    .then((res) => {
      const blob = new Blob([res.data], { type: 'application/pdf' })
      let fileName = res.headers['content-disposition'].split('filename=')[1]
      // Create an anchor element to trigger the download
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      // Specify the desired file name
      a.download = fileName

      // Trigger a click event on the anchor element to initiate the download
      a.click()

      // Clean up the URL object to release resources
      window.URL.revokeObjectURL(url)
      return res.data
    })
    .catch((err) => {
      console.log('sendInvoice', err)
    })
}
