
export const getUserAbilities = async (currentUser) => {
    const abilities = [];

    if (currentUser.roles.includes('admin')) {
      const subjects = ['clients', 'estimates', 'dispatch', 'billing', 'job', 'psm', 'crews', 'contacts', 'profiles', 'account-settings', 'shortcuts', 'logo', 'company-settings','admin-dashboard','crew-dispatch','tasks',"my-tasks", "reports"];
    
      if (currentUser.is_super_admin_profile) {
        subjects.push('switch');
      }
    
      abilities.push({
        action: 'manage',
        subject: subjects,
      });
    }
  
   else if (
      (currentUser?.roles?.includes('crew_leader') ||
        currentUser?.roles?.includes('crew')) &&
      currentUser?.roles?.every((role) =>
        ['crew_leader', 'crew',].includes(role)
      )
    ) {
      abilities.push({
        action: 'manage',
        subject: ['crew-dispatch','account-settings','logo',"my-tasks"],
      });
    }

   else if (currentUser?.roles?.includes('superadmin')){
      abilities.push({
        action: 'manage',
        subject: ['dashboard','company','account-settings'],
      })
    }

    else  if (currentUser?.roles?.includes('arborist')) {
      abilities.push({
        action: 'manage',
        subject: ['clients','estimates','dispatch','billing','job','psm','crews','contacts','profiles','account-settings','shortcuts','logo','tasks','my-tasks', "reports"],
      },
      );
    }
    else {
      abilities.push([])
    }
    return abilities
  };
  