import { ref } from 'vue'
import { defineStore } from 'pinia'
import axiosAuth from '@/utils/AxiosAuth'
import { useRuntimeStore } from '@/stores/runtimeStore'


export const useAdmin = defineStore('adminStore', () => {
  const adminSettings = ref({
    recurring_services_enabled: false,
    update_value_of: '',
    price_lock_in: 0,
    product_tax_rate: 0,
    service_tax_rate: 0,
    quickbooks_tax_code: {
      id: '',
      name: '',
      description: '',
      rates: [],
      total_rate: 0
    },
    company_name: '',
    company_id: '',
    company: {
      name: '',
      email: ''
    },
    quickbooks_logged_in: false,
    quickbooks_token_active: false,
    quickbooks_user: false
  })

  const skippedQbSignIn = ref(useCookie('skippedQbSignIn').value)
  console.log("..............skippedQbSignIn.........", skippedQbSignIn);
  const showRecurringServices = ref(false)
  // const useRecurringServices = ref(useToggle(showRecurringServices))

  const handleRecurringServices = () => {
    // useRecurringServices.value.toggle()

    // adminSettings.value.recurring_services_enabled =
      // useRecurringServices.value.showing
  }

  const skipQbAuth = () => {
    skippedQbSignIn.value = true
    localStorage.setItem('skippedQbSignIn', skippedQbSignIn.value)
  }

  const clearQbSkip = () => {
    skippedQbSignIn.value = false
    localStorage.removeItem('skippedQbSignIn')
  }

  const getAdminSettings = async () => {
    const root = `${useRuntimeStore().$state.apiRoot}/admin`
    await axiosAuth
      .get(`${root}/global_settings`)
      .then((res) => {
        adminSettings.value = res.data
      })
      .catch((err) => {
        console.log('getAdminSettings', err)
      })
  }

  const setAdminSettings = async (data) => {
    console.log('setAdminSettings', data)
    const root = `${useRuntimeStore().$state.apiRoot}/admin`
    await axiosAuth
      .post(`${root}/global_settings`, data)
      .then((res) => {
        adminSettings.value = res.data
      })
      .catch((err) => {
       })
  }

  const values = {
    adminSettings,
    // useRecurringServices,
    showRecurringServices,
    skippedQbSignIn
  }

  const actions = {
    getAdminSettings,
    setAdminSettings,
    handleRecurringServices,
    skipQbAuth,
    clearQbSkip
  }

  return {
    ...values,
    ...actions
  }
})
