import { defineStore } from 'pinia'
import axiosAuth from '@/utils/AxiosAuth'
import { notify } from 'notiwind'
import { useRuntimeStore } from '@/stores/runtimeStore'
import { useUserStore } from '@/stores/userStore'
import { useAdmin } from '@/stores/adminStore';
import { storeToRefs } from 'pinia'
import { useProfileStore } from '@/stores/profileStore'


export const useAttach = defineStore({
  id: 'attachment',
  state: () => ({
    imageUrl: '',
    newFile: null,
    disableSave: false,
    messageAttachments: [],
    signatureImage: null,
    templateAttachments: ''
  }),
  getters: {},
  actions: {
    resetImageUrl() {
      // This may be silly, but just cleaning up the
      // imageUrl in case it interferes with additional uploads.
      this.imageUrl = ''
      this.messageAttachments = []
    },

    async postProfileImage(file, profile) {
      this.disableSave = true
      const runtimeStore = useRuntimeStore()
      const { currentUser } = useUserStore()
      const { uploading} = storeToRefs(useProfileStore())

      const formData = new FormData()

      formData.append('file_obj', file)

      await axiosAuth
        .post(`${runtimeStore.apiRoot}/attachment/`, formData)
        .then((res) => {
          profile.profile_image = res.data
          if (profile.id === currentUser.id) {
            currentUser.profile_image = res.data
            uploading.value = false
          }
          notify(
            {
              group: 'success',
              title: 'Success',
              text: 'Your image was uploaded.'
            },
            4000
          ) // 4s
          this.disableSave = false
        })
        .catch((err) => {
          console.log(err)
          notify(
            {
              group: 'error',
              title: 'Error',
              text: err.message
            },
            4000
          ) // 4s
        })
    },
    updateProfileImage(file, profile) {
      const { currentUser} = storeToRefs(useUserStore())
      const { uploading} = storeToRefs(useProfileStore())
      return new Promise((resolve, reject) => {
        this.disableSave = true;
        const runtimeStore = useRuntimeStore();
        const formData = new FormData();
        formData.append('file_obj', file);
        let url = `${runtimeStore.apiRoot}/profile/${profile.id}/profile_image/`
         if (currentUser.value.roles.includes('superadmin')) {
                url = `${runtimeStore.apiRoot}/super-admin/${profile.id}/profile_image/`
               }
        axiosAuth
          .post(
            `${url}`,
            formData
          )
          .then((res) => {
            resolve(res.data);
            uploading.value = false
            notify(
              {
                group: 'success',
                title: 'Success',
                text: 'Profile image is updated successfully.'
              },
              5000
            ) // 4s
          })
          .catch((err) => {
            console.log(err); 
            notify(
              {
                group: 'error',
                title: 'Error',
                text: err.message
              },
              4000
            ); // 4s
            reject(err);
          });
      });
    },
    async  uploadMessageAttachments(files,email_template) {
  const runtimeStore = useRuntimeStore();

  try {
    for (const file of files) {
      const formData = new FormData();
      formData.append('file_obj', file);
      const res = await axiosAuth.post(`${runtimeStore.apiRoot}/attachment/`, formData);
      // Assuming messageAttachments is available here
        if (email_template){

            this.templateAttachments = res.data.url

        }else {
            this.messageAttachments.push(res.data.id);
        }
    }
  } catch (err) {
    console.error(err);
    notify(
      {
        group: 'error',
        title: 'Error',
        text: err.message
      },
      4000
    ); 
  }
},
    async uploadServiceAddressImage(clientId, serviceAddress, file) {
      const runtimeStore = useRuntimeStore()
      const formData = new FormData()

      formData.append('file_obj', file)
      axiosAuth
        .post(
          `${runtimeStore.apiRoot}/clients/${clientId}/service-address/${serviceAddress.id}/set-address-image`,
          formData
        )
        .then((res) => {
          console.log(res.data)

          this.messageAttachments.push(res.data.id)
        })
        .catch((err) => {
          console.log(err)
          notify(
            {
              group: 'error',
              title: 'Error',
              text: err.message
            },
            4000
          ) // 4s
        })
    },
    async uploadEstimateSignature(file) {
      this.disableSave = true
      const runtimeStore = useRuntimeStore()
      const formData = new FormData()

      formData.append('file_obj', file)

      await axiosAuth
        .post(`${runtimeStore.apiRoot}/attachment/`, formData)
        .then((res) => {
          console.log(res.data)
          this.signatureImage = res.data
          notify(
            {
              group: 'success',
              title: 'Success',
              text: 'Your signature was captured.'
            },
            4000
          ) // 4s
          this.disableSave = false
        })
        .catch((err) => {
          console.log(err)
          notify(
            {
              group: 'error',
              title: 'Error',
              text: err.message
            },
            4000
          ) // 4s
        })
    },
        async updateCompanyCover(companyId, file) {
      const runtimeStore = useRuntimeStore()
      const formData = new FormData()
      const { adminSettings } = useAdmin()
      formData.append('file_obj', file)
      axiosAuth
        .post(
          `${runtimeStore.apiRoot}/company/${companyId}/set_company_cover`,
          formData
        )
        .then((res) => {
          console.log(res.data)

         if (companyId === adminSettings.company.id) {
            if (!adminSettings.company.cover_image) {
             adminSettings.company.cover_image = [];
          }
            adminSettings.company.cover_image.id = res.data.id
            adminSettings.company.cover_image.url = res.data.url
           }
        })
        .catch((err) => {
          console.log(err)
          notify(
            {
              group: 'error',
              title: 'Error',
              text: err.message
            },
            4000
          ) // 4s
        })
    },

      async updateCompanyLogo(companyId, file) {
      const runtimeStore = useRuntimeStore()
      const formData = new FormData()
      const { adminSettings } = useAdmin()
      formData.append('file_obj', file)
      axiosAuth
        .post(
          `${runtimeStore.apiRoot}/company/${companyId}/set_company_logo`,
          formData
        )
        .then((res) => {
       if (companyId === adminSettings.company.id) {
        if (!adminSettings.company.logo) {
             adminSettings.company.logo = [];
          }
            adminSettings.company.logo.id = res.data.id
            adminSettings.company.logo.url = res.data.url

          }
        })
        .catch((err) => {
          console.log(err)
          notify(
            {
              group: 'error',
              title: 'Error',
              text: err.message
            },
            4000
          ) // 4s
        })
    },

      updateClientImage(clientId, Img) {
          return new Promise((resolve, reject) => {
              const runtimeStore = useRuntimeStore();
              // const { currentUser } = useUserStore();
              const formData = new FormData();
              formData.append('file_obj', Img);
              axiosAuth
                  .post(
                      `${runtimeStore.apiRoot}/clients/${clientId}/cover_image/`,
                      formData
                  )
                  .then((res) => {
                      this.imageUrl = res.data.url
                  })
                  .catch((err) => {
                      console.log(err);
                      notify(
                          {
                              group: 'error',
                              title: 'Error',
                              text: err.message
                          },
                          4000
                      ); // 4s
                      reject(err);
                  });
          });
      }
  }
})
